<template>
    <div ref="selectWrapper">
        <a-select
            ref="select"
            :value="value"
            :loading="loading"
            :open="openSelect"
            class="w-full"
            :mode="multiple ? 'tags' : 'default'"
            :size="size"
            :placeholder="placeholder"
            :allow-clear="allowClear"
            :list-height="listHeight"
            :show-search="showSearch"
            :placement="placement"
            :default-open="defaultOpen"
            :auto-clear-search-value="autoClearSearchValueCom"
            :disabled="disabled"
            :getPopupContainer="getPopupContainer"
            :dropdown-class-name="popupClassName"
            :max-tag-count="maxTagCount"
            :filter-option="filterOptionHandler"
            @change="listChange"
            @dropdownVisibleChange="dropdownVisibleChange"
            @focus="selectFocus"
            @blur="selectBlur"
            @deselect="deselect"
            @select="selectHandler"
            @popupScroll="popupScroll">
            <slot name="options" />
            <a-select-option
                v-for="item in list.results"
                :key="item[valueKey]"
                :value="item[valueKey]"
                :title="item[labelKey]">
                <slot 
                    v-if="$scopedSlots.option_item" 
                    name="option_item"
                    :data="item" />
                <div v-else class="flex items-center truncate">
                    <a-badge v-if="useOptionsBadge" :color="item[badgeColorKey]" /> {{ item[labelKey] }}
                </div>
            </a-select-option>
            <template slot="dropdownRender" slot-scope="menu">
                <v-nodes :vnodes="menu" />
                <div v-if="showDropdownClose" class="select_footer">
                    <a-spin :spinning="loading" size="small">
                        <a-button 
                            type="ui" 
                            ghost
                            block 
                            size="small"
                            @click="openSelect = false">
                            Закрыть
                        </a-button>
                    </a-spin>
                </div>
            </template>
            <template slot="notFoundContent">
                <div v-if="loading" class="flex justify-center p-2">
                    <a-spin size="small" />
                </div>
            </template>
            <template slot="suffixIcon">
                <i class="fi" :class="suffixIcon" />
            </template>
            <template v-if="$scopedSlots.removeIcon" slot="removeIcon">
                <slot name="removeIcon" />
            </template>
            <template v-if="$scopedSlots.clearIcon" slot="clearIcon">
                <slot name="clearIcon" />
            </template>
            <template v-if="$scopedSlots.placeholder" slot="placeholder">
                <slot name="placeholder" />
            </template>
        </a-select>
    </div>
</template>

<script>
const filterOption = (input, option) => {
    const chld = option.children()?.[0] || null
    if(chld) {
        const { children } = chld
        return children[1].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    } else
        return false
}
export default {
    props: {
        value: {
            type: [Array, String, Number],
            default: null,
        },
        apiUrl: {
            type: String,
            default: '/app_info/select_list/',
        },
        params: {
            type: Object,
            default: () => ({}),
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        initList: {
            type: Boolean,
            default: true,
        },
        valueKey: {
            type: String,
            default: 'id',
        },
        labelKey: {
            type: String,
            default: 'string_view',
        },
        oneSelect: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'large',
        },
        virtual: {
            type: Boolean,
            default: true,
        },
        allowClear: {
            type: Boolean,
            default: false,
        },
        listHeight: {
            type: Number,
            default: 256,
        },
        firstSelected: {
            type: Boolean,
            default: false,
        },
        infinity: {
            type: Boolean,
            default: false,
        },
        page_size: {
            type: Number,
            default: 15,
        },
        useOptionsBadge: {
            type: Boolean,
            default: false,
        },
        badgeColorKey: {
            type: String,
            default: 'color',
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
        placement: {
            type: String,
            default: 'bottomLeft',
        },
        placeholder: {
            type: String,
            default: '',
        },
        autoClearSearchValue: {
            type: Boolean,
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        popupClassName: {
            type: String,
            default: '',
        },
        maxTagCount: {
            type: Number,
            default: 30,
        },
        defaultOpen: {
            type: Boolean,
            default: false,
        },
        showDropdownClose: {
            type: Boolean,
            default: true,
        },
        drawerHeight: {
            type: [Number, String],
            default: '100%',
        },
        closeDrawerInSelect: {
            type: Boolean,
            default: true,
        },
        forceDrawer: {
            type: Boolean,
            default: false,
        },
        listObject: {
            type: [String, Boolean],
            default: 'selectList',
        },
        useFilterSort: {
            type: Boolean,
            default: false,
        },
        defaultSelectCode: {
            type: String,
            default: '',
        },
        suffixIcon: {
            type: String,
            default: 'fi-rr-angle-small-down',
        },
        changeFullObject: {
            type: Boolean,
            default: false,
        },
        usePopupContainer: {
            type: Boolean,
            default: false
        },
        getPContainer: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        }
    },
    data() {
        return {
            openSelect: false,
            loading: false,
            list: {
                results: [],
                next: true,
                count: 0
            },
            page: 0
        }
    },
    computed: {
        autoClearSearchValueCom() {
            return this.autoClearSearchValue !== undefined
                ? this.autoClearSearchValue
                : true;
        },
        scrollNum() {
            if(this.page_size >= 10) {
                return 100
            }
            return 50
        }
    },
    methods: {
        getPopupContainer() {
            if(this.usePopupContainer) {
                return this.getPContainer()
            } else
                return this.$refs.selectWrapper
        },
        filterOptionHandler(input, option) {
            if (this.useFilterSort && !this.infinity) {
                return filterOption(input, option);
            }
            return false;
        },
        async getList() {
            try {
                this.loading = true;
                const params = { ...this.params };

                if (params.filters) {
                    params.filters = JSON.stringify(params.filters);
                }

                if (this.infinity) {
                    this.page += 1;
                    params.page = this.page;
                    params.page_size = this.page_size;
                }

                const { data } = await this.$http.get(this.apiUrl, { params })
                if (this.infinity) {
                    if (data?.results?.length) {
                        this.list.results = [...this.list.results, ...data.results];
                        if (this.firstSelected && !this.value) {
                            const find = this.list.results.find(
                                (item) => item[this.valueKey] === this.defaultSelectCode
                            );
                            this.$emit('input', find ? find[this.valueKey] : data.results[0][this.valueKey])
                        }
                        if(this.oneSelect && this.list.results.length === 1) {
                            this.$emit('input', this.list.results[0][this.valueKey])
                        }
                    }
                    this.list.next = data.next;
                    this.list.count = data.count;
                } else if (data?.[this.listObject]?.length) {
                    this.list.results = data[this.listObject]
                    this.list.count = data[this.listObject].length;
                    if(this.oneSelect && data[this.listObject].length === 1) {
                        this.$emit('input', data[this.listObject][0][this.valueKey])
                    }
                } else {
                    this.list.results = data
                    this.list.count = data.length
                    if(this.oneSelect && data.length === 1) {
                        this.$emit('input', data[0][this.valueKey])
                    }
                }
            } catch (e) {
                console.error('Error fetching list:', e);
            } finally {
                this.loading = false;
            }
        },
        listChange(value) {
            if (this.changeFullObject) {
                const find = this.list.results.find((f) => f[this.valueKey] === value)
                this.$emit('input', { value, find });
            } else {
                this.$emit('input', value)
            }
            this.$emit('change', value)

            const find = this.list.results.find((f) => f[this.valueKey] === value)
            this.$emit('changeGetObject', find || null)
        },
        dropdownVisibleChange(open) {
            this.openSelect = open;
            this.$emit('dropdownVisibleChange', open);
            if (open && !this.$scopedSlots.options && !this.initList && !this.list.results.length) {
                this.getList()
            }
        },
        popupScroll(event) {
            this.$emit('popupScroll', event)
            if (this.infinity && this.list.next) {
                const target = event.target
                if (!this.loading && (target.scrollTop + target.offsetHeight) + this.scrollNum >= target.scrollHeight)
                    this.getList()
            }
        },
        deselect(value) {
            this.$emit('deselect', value);
        },
        selectHandler(value) {
            this.$emit('select', value);
        },
        selectFocus() {
            this.$emit('focus');
        },
        selectBlur() {
            this.$emit('blur');
        },
        listReload() {
            this.value = this.multiple ? [] : null;
            this.list = { results: [], next: true, count: 0 };
            this.page = 0;
        },
    },
    mounted() {
        if (!this.$scopedSlots.options && this.initList) {
            this.getList();
        }
    }
}
</script>
