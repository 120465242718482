import task from '@apps/vue2TaskComponent/lang/ru'
import chat from '@apps/vue2ChatComponent/lang/ru'
import profiler from './mobules/profiler/ru'
import group from '@apps/vue2GroupsAndProjectsComponent/lang/ru'
import noty from '@apps/Notifications/lang/ru'
import support from '@apps/Support/lang/ru'
import meeting from '@apps/vue2MeetingComponent/lang/ru'
import files from './mobules/files/ru'
import upload from './mobules/upload/ru'
import products from './mobules/products/ru'
import reports from './mobules/reports/ru'
import calendar from '@apps/Calendar/lang/ru'
import auth from './mobules/auth/ru'
import table from './mobules/table/ru'
import dashboard from '@apps/Dashboard/lang/ru'
import inquiries from '@apps/Inquiries/lang/ru'
import history from '@apps/History/lang/ru'
import comment from '@apps/vue2CommentsComponent/lang/ru'
import invest from '@apps/InvestProject/lang/ru'
import sports from '@apps/SportsFacilities/lang/ru'
import gallery from '@apps/Gallery/lang/ru'

export default {
    "ru": "Русский",
    "en": "Английский",
    "kk": "Казахский",
    "profile": "Профиль",
    "show_all": "Показать все",
    "select_filter": "Выбрать фильтр",
    "select": "Выбрать",
    "filters": "Фильтры",
    "find": "Поиск",
    "reset": "Сбросить",
    "close": "Закрыть",
    "yes": "Да",
    "no": "Нет",
    "no_data": "Данных нет",
    "enable": "Включить",
    "exclude": "Исключить",
    "clear": "Очистить",
    "name": "Название",
    "new": "Новая",
    "in_work": "В работе",
    "on_pause": "На паузе",
    "on_check": "На проверке",
    "on_rework": "На переделке",
    "completed": "Завершена",
    "to_work": "Начать выполнение",
    "to_pause": "Приостановить",
    "to_check": "На проверку",
    "to_rework": "Переделать",
    "to_completed": "Завершить",
    "to_new": "Новая",
    "dead_line": "Крайний срок",
    "priority": "Приоритет",
    "tasks.TaskModel": "задачу",
    "enter_your_message": "Введите сообщение",
    "reply_to_message": "Ответить на сообщение",
    "systemic": "Системное",
    "uncover": "Раскрыть",
    "collapse": "Свернуть",
    "comments": "Комментарии",
    "answers": "Ответы",
    "hide_replies": "Скрыть ответы",
    "load_more_answers": "Загрузить еще ответы",
    "answer_for": "ответ для",
    "load_more": "Загрузить еще",
    "enter_your_comment": "Введите комментарий",
    "comment2": "Комментарий",
    "comment_added": "Комментарий добавлен",
    "comment_delete": "Комментарий удален",
    "to_answer": "Ответить",
    "share_to_chat": "Поделиться",
    "edit_task": "Редактировать задачу",
    "add_task": "Добавить задачу",
    "select_date": "Выбрать дату",
    "select_performer": "Выбрать исполнителя",
    "auth": "Авторизация",
    "reg_text": "Еще нет учетной записи?",
    "reg": "Зарегистрируйтесь",
    "your_password": "Ваш пароль",
    "your_email": "Ваш E-mail",
    "sing_in": "Войти",
    "forgot_your_password": "Забыли пароль?",
    "field_required": "Обязательно для заполнения",
    "required_sym": "Минимум {sym} символов",
    "fill_required_fields": "Заполните обязательные поля",
    "authorisation_error": "Ошибка авторизации",
    "crm.GoodsOrderModel": "Заказ",
    "tasks.TaskSprintModel": "Спринт",
    "files": "Файлы",
    "remove": "Удалить",
    "pick_files": "Прикрепленные файлы",
    "file": "Файл",
    "image_show": "Просмотр изображения",
    "meetings.PlannedMeetingModel": "Собрание",
    "processes.FinancialApplicationModel": "Бизнес процесс",
    "change_avatar": "Изенить аватар",
    "delete_avatar": "Удалить аватар",
    "success_del_avatar": "Аватар удален",
    "success_avatar": "Аватар изменен",
    "max_file_size": "Максимальный размер файла {size}МБ",
    "file_size": "Размер файла",
    "megabytes": "Мб",
    "max_file_h_w": "Минимальная высота и ширина изображения {size}px",
    "update": "Обновить",
    "file_modal_title": "Отправить собщение с файлом",
    "file_max_count": "В сообщении можно отправить только {count} файлов",
    "file_and_image": "Файл/Изображение",
    "monochrome_mode": "Монохромный режим",
    "language": "Язык",
    "desktop_version": "Десктопная версия",
    "menu_type": "Тип меню",
    "mini_menu": "Маленькое меню",
    "expanded_menu": "Развернутое меню",
    "dynamic_menu": "Динамическое",
    "header_menu": "Меню в шапке",
    "mobile_version": "Мобильная версия",
    "small": "Маленький",
    "update_theme": "Обновить тему",
    "attached_files": "Прикрепленные файлы",
    "file": "Файл",
    "menu_modules": "Модули меню",
    "menu_sort_alert": "Пункты меню также можно сортировать путем перетаскивания в главном меню, для этого зажмите пункт меню а потом перенесите в нужную позицию",
    "min_one_module": "Минимум должен быть 1 модуль",
    "icon": "Иконка",
    "move": "Переместить",
    "visible": "Видимый",
    "hidden": "Скрытый",
    "mobile_modules": "Мобильные модули",
    "drag_icons_alert": "Перетаскивайте ярлыки модулей на необходимые позиции",
    "menu": "Меню",
    "active_modules": "Активные модули",
    "deactivated_modules": "Деактивированные модули",
    "enter_current_password": "Введите текущий пароль",
    "enter_new_password": "Введите новый пароль",
    "repeat_new_password": "Повторите новый пароль",
    "change_password": "Сменить пароль",
    "passwords_mismatch": "Пароли не совпадают",
    "password_changed_successfully": "Пароль успешно изменен",
    "error": "Ошибка",
    "loading_error": "Ошибка загрузки",
    "check_input_data": "Проверьте правильность введенных данных",
    "field_required": "Это поле обязательно",
    "required_sym": "Минимальная длина: {sym} символов",
    "last_name": "Фамилия",
    "first_name": "Имя",
    "middle_name": "Отчество",
    "job_title": "Должность",
    "contact_phone": "Контактный телефон",
    "birthday": "Дата рождения",
    "select_date": "Выбрать дату",
    "is_make_events_in_task_automatically": "Создавать для меня события автоматически при наличии дедлайнов в задачах",
    "save": "Сохранить",
    "edit": "Редактировать",
    "cancel": "Отмена",
    "qr_code_info": "Чтобы подключить уведомления в Telegram, отсканируйте QR-код или перейдите по ссылке",
    "by_link": "по ссылке",
    "unsubscribe": "Отписаться",
    "recommendations": "Рекомендации",
    "fill_last_name": "Заполните Фамилию",
    "fill_first_name": "Заполните Имя",
    "fill_middle_name": "Заполните Отчество",
    "fill_job_title": "Заполните Должность",
    "fill_birthday": "Заполните Дату рождения",
    "upload_avatar": "Загрузите Аватар",
    "no_data": "Нет данных",
    "reconciliation_act": "Акт сверки",
    "name": "Название",
    "currency": "Валюта",
    "reconciliation_act_from": "Акт сверки от",
    "error_occurred": "Ошибка",
    "not_widget": "Такого виджета не существует",
    "edit_avatar": "Изменить аватар",
    "success_avatar": "Аватар успешно обновлен!",
    "max_file_h_w": "Минимальная ширина и высота {{size}}px.",
    "install_prompt": "Установить приложение для быстрого доступа?",
    "install": "Установить",
    "my_delivery_points": "Мои точки доставки",
    "old_lk": "Старый ЛК",
    "change_password": "Сменить пароль",
    "interface": "Интерфейс",
    "other": "Прочее",
    "users_filter": "Фильтр пользователей",
    "old_selected": "Ранее выбранные",
    "clear_cache": "Очистить кэш",
    "client_version": "Версия клиента",
    "ui_components": "UI components",
    "send_push": "Отправить push",
    "clear_cache_all": "Очистить кэш у всех",
    "title": "Заголовок",
    "text": "Текст",
    "link": "Ссылка",
    "send": "Отправить",
    "logout": "Выход",
    "host": "Хост",
    "build_date": "Дата билда",
    "add_task": "Добавить задачу",
    "add_conference": "Добавить конференцию",
    "add_event": "Добавить событие",
    "add_organization": "Добавить организацию",
    "widget_not_found": "Виджет для текущей страницы не найден в папке @/views/Dashboard/PageWidgets/<strong>{{ widgetName }}</strong>.vue",
    "page_configuration": "Конфигурация страницы",
    "filter": "Фильтр",
    "f_select": "Выбрать",
    "f_search": "+ поиск",
    "f_search_and_filter": "Фильтр + поиск",
    "d_start": "Начальная",
    "d_end": "Конечная",
    "f_input_p": "Введите значение",
    "f_select_p": "Выберите из списка",
    "f_user_p": "Введите имя пользователя",
    "network_error": "Проблема с интернет соединением. Проверьте подключение к интернету!",
    "app_update_text": "Необходимо обновить приложение",
    "app_update": "Обновить",
    "previouslySelected": "Ранее выбранные",
    "notice_browser_language": "Мы заметили, что язык вашего браузера — {lang}.",
    "keep_current_language": "Нет, оставить текущий язык",
    "change_language": "Да, изменить на {lang}",
    "change_site_language": "Хотите изменить язык сайта на {lang} для более удобного использования?",
    ...support,
    ...noty,
    ...task,
    ...chat,
    ...profiler,
    ...group,
    ...meeting,
    ...files,
    ...upload,
    ...products,
    ...reports,
    ...calendar,
    ...auth,
    ...table,
    ...dashboard,
    ...inquiries,
    ...history,
    ...comment,
    ...invest,
    ...sports,
    ...gallery
}
